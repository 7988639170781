<template>
  <NuxtLink class="grower_item" :to="data.link">
 

    <div class="about_row">

      <div class="num">
        {{ num }}
      </div>
    
      <div class="photo">
        <div :class="'user_range u_range_' + data.status"></div>
        <img :src="data.avatar_small" :alt="data.name">
      </div>
      <div class="names" itemscope="" itemtype="http://schema.org/Person">
        <div class="name" itemprop="name">
          {{ data.name }}
        </div>
        <div class="status">
          {{  data.status && $constants.status[data.status] ? $t($constants.status[data.status]) : '' }}
          <i @click.prevent.stop="toChat" class="icon-message chat-btn"></i>
        </div>
      </div>
      <div class="stat">
 

        <div :class="'item item_gp ' + (!data.avg_weight_plant ? 'is_empty' : '')">
          <div class="value">
            <i class="gp"></i>
            {{ data.avg_weight_plant ? data.avg_weight_plant : '' }}
          </div>
          <div class="ttl">{{ $t('universal_unit_gram') }}/{{ $t('universal_unit_plant') }}</div>
        </div>

        <div :class="'item item_diaries ' + (!data.cnt_reports ? 'is_empty' : '')">
          <div class="value">
            <i class="icon-diary"></i>
            {{ data.cnt_reports }}
          </div>
          <div class="ttl">{{ $t('growers_view_user_info_diaries') }}</div>
        </div>
        <div :class="'item likes ' + (!data.cnt_likes ? 'is_empty' : '')">
          <div class="value">
            <i class="icon-leaf"></i>
            {{ data.cnt_likes }}
          </div>
          <div class="ttl">{{ $t('growers_view_user_info_likes') }}</div>
        </div>

      </div>

    </div>
 



  </NuxtLink>
</template>


<script setup>

const { hooks } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    required: true
  }, 
  num: {
    type: Number,
    required: false
  }, 
})

const toChat = function() {

  hooks.callHook('chat:start', props.data.id).catch(() => {
    console.log('chat:start hook');    
  })

}

</script>
   

<style scoped>
  
.grower_item {
  width: 100%;
  display: block;
  position: relative;
  border-radius: 3px;
  margin-top: 7px;

  text-align: left;
  border-bottom: 1px var(--un-element-color-gray) solid;
  padding-bottom: 7px;
  container: grower_row / inline-size;
}
 

.grower_item .about_row {
    width: 100%;
    display: flex;
    position: relative;
    min-height: 65px;
    justify-content: space-between;
}
.grower_item .stat_grid{
    display: none;
}


.grower_item .num{
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    color: #bbbbbb;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto 0;
    flex-shrink: 0;
}

.grower_item  .position .icon{
    font-size: 0.75rem;
    margin-top: -3px;
    color: var(--un-primary-back-color);
    display: inline-block;
}
.grower_item  .position .icon.up{
    color: var(--un-primary-back-color);
}

.grower_item  .position .icon.down{
    color: red;
}

.grower_item  .position .icon span {
    font-weight: bold;
    font-size: 0.75rem;
    vertical-align: bottom;
    margin-left: 3px;
}


.grower_item .about_row .photo {
        display: inline-block;
        width: 80px;
        vertical-align: top;
        position: relative;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
}

.grower_item .about_row .photo img {
   border-radius: 150px;
    min-height: 50px;
    width: 50px;
}

.grower_item .about_row .names {
    display: inline-block;
    width: calc(40% - 110px);
    vertical-align: top;
    position: relative;
    padding-top: 10px;
}

.grower_item .about_row .photo .user_range {
       font-size: 1.6rem;
    /* margin-top: 6px; */
    margin-right: 15px;
    position: absolute;
    right: 0px;
    top: 37px;
}

.grower_item .about_row .names .status .btn_chat{
    display: inline-block;
    color: gray;
    font-size: 0.85rem;
    padding: 5px;
    border-radius: 3px;
    margin-left: 5px;
}
.grower_item .about_row .names .status .btn_chat.online{

    color: var(--un-primary-back-color);
}
.grower_item .about_row .names .status .btn_chat:hover{
    color: white;
    background-color: var(--un-primary-back-color);
}

.grower_item .about_row .names .name {
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grower_item .about_row .names .status {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.grower_item .about_row .names .status .icon.circle.online{
    color: var(--un-primary-back-color);
}
.grower_item .about_row .names .status .icon.circle{
    color: gray;
    font-size: 0.75rem;
}
.grower_item .about_row .names .status .icon.trophy{
    color: #cccccc;
    display: none;
}
.grower_item .about_row .names .status .icon.gold{
    color: gold;
}
.grower_item .about_row .stat {
    display: flex;
        justify-content: flex-end;
    width: 60%;
    vertical-align: top;
    position: relative;
    padding-top: 10px;
    padding-right: 10px;
}

.grower_item .about_row .stat .item {
    display: inline-block;
    text-align: center;

    min-width: 90px;
}
.grower_item .about_row .stat .item.is_empty {
        opacity: 0.4;
}
.grower_item .is-awards{
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('/images/trophy/diary_two.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: -5px;
    margin-left: -7px;
    margin-right: -7px;
}
.grower_item .is-awards.gold{
    background-image: url('/images/trophy/diary_first.png');
}
.grower_item .is-awards-user{
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('/images/trophy/grower_first.svg');
    width: 25px;
    height: 26px;
    display: inline-block;
    margin-bottom: -5px;
    margin-left: -7px;
    margin-right: -7px;
}
.grower_item .item_awards .value{
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -11px;
}
.grower_item .item_awards_3 .is-awards:nth-child(2){
    position: absolute;
    margin-left: -42px;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    z-index: -1;
}
.grower_item .item_awards_3 .is-awards:nth-child(3){
    position: absolute;
    margin-left: -22px;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    z-index: -1;
}


.grower_item .item_awards_2 .is-awards:nth-child(1){

}
.grower_item .item_awards_2 .is-awards:nth-child(2){
margin-left: -24px;
    position: absolute;
    z-index: -1;
}

.grower_item .about_row .stat .item .value {
    font-size: 1.1rem;
    /* color: var(--un-text-color-gray); */
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
 .grower_item .stat .item .gw,
 .grower_item .stat .item .gp{

    width: 18px;
    height: 18px;
    vertical-align: top;
 }

.grower_item .about_row .stat .item .ttl {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grower_item .about_row .stat .item.likes .value i{
     color: var(--un-primary-back-color);
}

.grower_item .favorite_row {
    padding-left: 68px;
    white-space: nowrap;
    overflow-x: hidden;
    opacity: 0.3;
    margin-right: 20px;
}
.grower_item:hover .favorite_row {
    opacity: 1;
}
.grower_item .favorite_row .favs{
    text-align: right;
}
.grower_item .favorite_row .social{
        display: inline-block;
    width: 60px;
    margin-right: 10px;
    text-align: center;
    float: left;
}

.grower_item .favorite_row .social .social_instagram{
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
}
.grower_item .favorite_row .social .icon.youtube{
    font-size: 1.1rem;
    color: #e52d27;
}

.grower_item .favorite_row .social .icon.facebook{
    font-size: 1.1rem;
    color: #3b5999;
}

.grower_item .favorite_row .favs .ttl {
    display: inline-block;
    font-weight: bold;
}

.grower_item .favorite_row .fav_item {
    display: inline-block;
    margin-left: 15px;
}

.grower_item .favorite_row .fav_item .hint {
    color: gray;
    display: inline-block;
}

.grower_item .favorite_row .fav_item .img {
    display: inline-block;
    width: 19px;
    vertical-align: middle;
    margin: 0px 10px;
}
.grower_item .favorite_row .fav_item .img img{
    display: inline-block;
    width: 100%;
    margin-top: 3px;
    margin-bottom: -3px;
}

.grower_item .favorite_row .fav_item .name {
    display: inline-block;
}


.chat-btn{
  cursor: pointer;
  padding: 10px;
  padding: 7px 10px;
    margin-left: -6px;
}



@container grower_row (max-width: 500px) { 
  .grower_item .about_row .stat .likes,
  .grower_item .about_row .stat .item_gp{
    display: none;
  }
}


@container grower_row (max-width: 700px) {
  .grower_item .about_row .stat{
    width: 180px;
  }
  .grower_item .about_row .names{
    width: calc(100% - 260px);
  }
}




</style>
